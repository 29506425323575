import React from "react";
import { Link } from "react-scroll";


export default function Developerlogo() {
  return (
    <div className="dev_clients_logo_area">
      <div className="container">
        <div className="clients_intrigration">
        <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
            <img  width={50} height={50} src={require("../../image/320345b-React_Logo_256x256.avif")} alt="" />
          </Link>
          <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
            <img  width={50} height={50} src={require("../../image/nodejs-development-services.webp")} alt="" />
          </Link>
          <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
            <img  width={50} height={50} src={require("../../image/aws.png")} alt="" />
          </Link>
          <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
            <img  width={50} height={50} src={require("../../image/NET_Core_Logo.svg.png")} alt="" />
          </Link>
          <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
            >      
            <img  width={50} height={50} src={require("../../image/Circleci-icon-logo.svg.png")} alt="" />
          </Link>
          <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
            <img  width={50} height={50} src={require("../../image/Postgresql_elephant.svg.png")} alt="" />
          </Link>
          <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
            <img  width={50} height={50} src={require("../../image/mongodb-logo.png")} alt="" />
          </Link>
          <Link
                    className="c_items"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
            <img  width={50} height={50} src={require("../../image/25231.png")} alt="" />
          </Link>
          
        </div>
      </div>
    </div>
  );
}
