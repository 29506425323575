import React from "react";
import {useSelector} from 'react-redux';

export default function Exprience() {
  const lang = useSelector(state => state.lang);
  return (
    lang === "English" ? 
    <div className="exprience_inner">
      <div className="media exprience_item">
        <div className="media-left">
          <a href="https://www.linkedin.com/in/kai-phung" target="_blank" rel="noopener noreferrer">
            <img
              src={require("../../image/developer-img/design-logo1.png")}
              alt=""
            />
          </a>
        </div>
        
        <div className="media-body">
          <h4>PERFORMANCE ANALYSIS</h4>
          <span>Sept 2019 - June 2022 (3 years)</span>
          <p>
          • Analysis website performance using Google Analysis and Facebook Pixel Tracking.
          </p>
          <p>
          • Improve website performance with UI/UX team to deliver the most optimized version for clients.
          </p>
          <p>
          • Debug and validate function on website with developer department.
          </p>
          <p>
          • Learned the object-oriented programming syntax in .NET framework and javacript syntax in React and Node.js framework to better manage the final code delivered by developer department.
          </p>
        </div> 
      {/* </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/design-logo2.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Former Graphic Designer</h4>
          <span>March, 2011 - June, 2012 (1 year, 3 Month)</span>
          <p>
            Proven ability to lead and manage a wide variety of design and
            development projects in team and independent situations.
          </p>
        </div>
      </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/design-logo3.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Head of Design</h4>
          <span>March, 2005 - March, 2011 (6 years)</span>
          <p>
            Proven ability to lead and manage a wide variety of design and
            development projects in team and independent situations.
          </p>
        </div>
      </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/design-logo4.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Graphic Designer (intern)</h4>
          <span>March, 2015 - June, 2017 (1 year, 3 Month)</span>
          <p>
            Proven ability to lead and manage a wide variety of design and
            development projects in team and independent situations.
          </p>
        </div> */}
      </div>
    </div> : 
        <div className="exprience_inner">
      <div className="media exprience_item">
        <div className="media-left">
          <a href="https://www.linkedin.com/in/kai-phung" target="_blank" rel="noopener noreferrer">
            <img
              src={require("../../image/developer-img/design-logo1.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>PERFORMANCE ANALYSIS</h4>
          <span>Syyskuu 2019 - Kesäkuu 2022 (3 vuotta)</span>
          <p>
          • Analysoinut verkkosivuston suorituskykyä, Google Analyticsin ja Facebook Pixel seurannan avulla.
          </p>
          <p>
          • Parantanut verkkosivustojen suorituskykyä UI/UX -tiimin avulla tarjoten asiakkaille optimoidun version.
          </p>
          <p>
          • Suorittanut virheiden korjausta ja varmistanut toiminnallisuutta web-käyttöliittymään.
          </p>
          <p>
          • Ohjelmoinut käyttöliittymän, jonka avulla käyttäjä on voinut valita lentoradan kartalta.
          </p>
        </div>
    </div>
    </div>
  );
}
