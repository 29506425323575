import React, { Component } from "react";
import Sticky from "react-stickynode";
import { Link } from "react-scroll";
import actions from "../../actions";
import { connect } from "react-redux";

class Navbar extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    var { mClass, mContainer, mainlogo, stickylogo } = this.props;
    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
          <div className={`container ${mContainer}`}>
            
            
            <button className="login" onClick={() => {
              if(this.props.lang === "English") {
                this.props.FinMode();
                console.log(this.props.lang);
              }
              else {
                this.props.EngMode();
                console.log(this.props.lang);
              }
            }}> {this.props.lang === "English" ? 'Switch Language to Finnish' : 'Kielenvaihto Englanniksi'}</button>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div
              className="collapse navbar-collapse offset"
              id="navbarSupportedContent"
            >
              <ul className="nav navbar-nav m-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
                    {this.props.lang === "English" ? 'Home' : 'Kotisivu'}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
                    {this.props.lang === "English" ? 'About' : 'Profiili'}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
                    Portfolio
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="testimonial"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
                    {this.props.lang === "English" ? 'References' : 'SUOSITTELIJAT'}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="experience"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
                    {this.props.lang === "English" ? 'Experience' : 'TYÖKOKEMUS'}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="contacts"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
                    {this.props.lang === "English" ? 'Contact' : 'YHTEYSTIEDOT'}
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="https://github.com/KaiDoingCode"
                    className="btn get-btn get-btn-two d-lg-none d-md-block login"
                    target="_blank" rel="noopener noreferrer"
                  >
                    {this.props.lang === "English" ? 'To My Github' : 'Githubiini'}
                  </a>
                </li>
                
                
              </ul>
              
              <ul className="nav navbar-nav navbar-right d-md-none d-lg-block">
                <li className="nav-item">
                  <a href="https://github.com/KaiDoingCode" className="login"  target="_blank" rel="noopener noreferrer">
                    {this.props.lang === "English" ? 'To My Github' : 'Githubiini'}
                  </a>
                </li>
              </ul>

              
              
            </div>
          </div>
        </nav>
      </Sticky>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({ 
  EngMode: () => dispatch(actions.EngMode()),
  FinMode: () => dispatch(actions.FinMode())
});


const mapStateToProps = state => ({
  lang: state.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
