import React, {Component} from 'react';
import Slider from 'react-slick';
import actions from "../../actions";
import { connect } from "react-redux";

 class TestimonialSlider extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        pauseOnHover:true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <div>
          <Slider {...settings} className="testimonial_slider">
                
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/our-coaches-2.jpg')} alt=""/>
                    </div>
                    <h6>Tommi Puisto | ApplyLight Oy | Tampere, {this.props.lang === "English" ? "Finland:" : "Suomi"}</h6>
                    <span>Engineer</span>
                    <p><strong>{this.props.lang === "English" ? "Phone:" : "Puhelin:"}</strong> +358 50 918 4503</p> 
                    <p><strong>{this.props.lang === "English" ? "Email:" : "Sähköposti:"}</strong> tommi.puisto@applylight.fi</p>
                </div>
                <div className="item">
                    <div className="author_img">
                        <img src={require('../../image/our-coaches-7.jpg')} alt=""/>
                    </div>
                    <h6>Joey Vuong | TikTok Oy | Singapore</h6>
                    <span>Product Manager</span>
                    <p><strong>{this.props.lang === "English" ? "Phone:" : "Puhelin:"}</strong> +65 84 805 910</p> 
                    <p><strong>{this.props.lang === "English" ? "Email:" : "Sähköposti:"}</strong> duy.vuong@tiktok.com</p>
                </div>
          </Slider>
        </div>
      );
    }
  }
  const mapDispatchToProps = (dispatch) => ({ 
    EngMode: () => dispatch(actions.EngMode()),
    FinMode: () => dispatch(actions.FinMode())
  });
  
  
  const mapStateToProps = state => ({
    lang: state.lang
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(TestimonialSlider);