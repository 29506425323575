const EngMode = () => {
    return {
        type: "English"
    }
}

const FinMode = () => {
    return {
        type: "Finnish"
    }
}

export default {
    EngMode,
    FinMode
}