import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import SectionTitleTwo from "../component/Banner/SectionTitleTwo";
import Form from "./Form";
import { connect } from "react-redux";

class ContactTwo extends Component {
  render() {
    let { secClass, lang } = this.props;
    return (
      lang === "English" ?
      <section
        className={`contact-area contact-area-two bg_color ${secClass}`}
        id="contacts"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="get_info">
                <SectionTitleTwo
                  stitle="Contact me"
                  btitle="I am available 24/7"
                />
                <div className="media get_item">
                  <i className="flaticon-phone"></i>
                  <div className="media-body">
                    <h6>Call Me Now</h6>
                    <a href="tel:+358414930483">(+358) 41 493 0483</a>
                  </div>
                </div>
                <div className="media get_item">
                  <i className="flaticon-chat"></i>
                  <div className="media-body">
                    <h6>Email Me</h6>
                    <a href="mailto:vantu20896@gmail.com">vantu20896@gmail.com</a>
                  </div>
                </div>
                <div className="media get_item">
                  <i className="flaticon-pin"></i>
                  <div className="media-body">
                    <h6>My Location</h6>
                    <p>Tampere, Finland</p>
                  </div>
                </div>
                {/* <div className="media get_item">
                  <i className="flaticon-sound"></i>
                  <div className="media-body">
                    <h6>Listen To Me</h6>
                    <a href=".#">john.com</a>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-7 col-md-12">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="input_form">
                  <SectionTitleTwo
                    stitle="Contact us"
                    btitle="Let me know here Know Here"
                  />
                  <Form />
                </div>
              </Reveal>
            </div> */}
          </div>
        </div>
      </section>
     :
    <section
        className={`contact-area contact-area-two bg_color ${secClass}`}
        id="contacts"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="get_info">
                <SectionTitleTwo
                  stitle="Yhteystiedot"
                  btitle="Olen tavoitettavissa 24/7"
                />
                <div className="media get_item">
                  <i className="flaticon-phone"></i>
                  <div className="media-body">
                    <h6>Puhelimeni</h6>
                    <a href="tel:+358414930483">(+358) 41 493 0483</a>
                  </div>
                </div>
                <div className="media get_item">
                  <i className="flaticon-chat"></i>
                  <div className="media-body">
                    <h6>Sähköpostini</h6>
                    <a href="mailto:vantu20896@gmail.com">vantu20896@gmail.com</a>
                  </div>
                </div>
                <div className="media get_item">
                  <i className="flaticon-pin"></i>
                  <div className="media-body">
                    <h6>Kaupunkini</h6>
                    <p>Tampere, Suomi</p>
                  </div>
                </div>
                {/* <div className="media get_item">
                  <i className="flaticon-sound"></i>
                  <div className="media-body">
                    <h6>Listen To Me</h6>
                    <a href=".#">john.com</a>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-7 col-md-12">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="input_form">
                  <SectionTitleTwo
                    stitle="Contact us"
                    btitle="Let me know here Know Here"
                  />
                  <Form />
                </div>
              </Reveal>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}




const mapStateToProps = state => ({
  lang: state.lang
});

export default connect(mapStateToProps)(ContactTwo);