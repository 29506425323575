import React, { Component } from "react";
import Exprience from "./Exprience";
import actions from "../../actions";
import { connect } from "react-redux";

class DeveloperExprience extends Component {
  render() {
    return (
      <section id="experience" className="exprience_area sec_pad">
        <img
          className="shape one"
          src={require("../../image/php.png")}
          alt=""
        />
        <img
          className="shape two"
          src={require("../../image/img-6.png")}
          alt=""
        />
        <img
          className="shape three"
          src={require("../../image/img-4.png")}
          alt=""
        />
        <img
          className="shape four"
          src={require("../../image/developer-img/dot_big.png")}
          alt=""
        />
        <div className="container">
          <div className="dev_tittle mb_70">
            <h2>{this.props.lang === "English" ? 'Experience' : 'Työkokemus'}</h2>
          </div>
          <Exprience />
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({ 
  EngMode: () => dispatch(actions.EngMode()),
  FinMode: () => dispatch(actions.FinMode())
});


const mapStateToProps = state => ({
  lang: state.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperExprience);