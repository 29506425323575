import React from "react";
import TestimonialSlider from "../Testimonial/TestimonialSlider";
import { connect } from "react-redux";
import actions from "../../actions";
import {useSelector, useDispatch} from 'react-redux';

const DeveloperTestimonial = () => {
  const lang = useSelector(state => state.lang);
  const dispatch = useDispatch();
  return (
    <section
      id="testimonial"
      className="testimonial_area testimonial_area_three"
    >
      <img
        className="shape one"
        src={require("../../image/developer-img/testimonial_bg_circle.png")}
        alt=""
      />
      <img
        className="shape two"
        src={require("../../image/developer-img/dot.png")}
        alt=""
      />
      <div className="container">
        <div className="dev_tittle mb_70">
          <h2>{lang === "English" ? 'Reference' : 'Suosittelijat'}</h2>
          <p></p>
        </div>
        <TestimonialSlider />
      </div>
    </section>
  );
};
export default DeveloperTestimonial;
