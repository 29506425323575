const switchLang = (state = {lang: "English"}, action) => {
    switch(action.type){
        case "English":
            return {...state, lang: "English"};
        case "Finnish":
            return {...state, lang: "Finnish"};
        default: 
            return state;
    }
}

export default switchLang;