const jhonDataFinnish = {
  name: "John Deo.",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "Visual Designer & Front-End Developer,",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "github",
      url: "https://github.com/KaiDoingCode",
      className: "social_blogger_square",
    },
    {
      name: "email",
      url: "mailto:vantu20896@gmail.com",
      className: "social_googleplus",
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/kai-phung",
      className: "social_linkedin",
    },
  ],
  aboutme: "Esittelyni - Tu Phung",
  aboutdetails:
    "Minulaonerinomaisetongelmanratkaisutaidotjavahvataustadata-analyseistä. Olentotunutkansainvälisintyöympäristöihin,työskennelytkansainvälisesä toimistosa,mikäedelytänytintensivistäongelmanratkaisukykyäja-vastuuta.",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Support",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "YHTEYSTIEDOT",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Tampere - Finland",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Puhelin: +358.41.493.0483",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Sähköposti: vantu20896@gmail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Henkilökohtainen Webi: https://github.com/KaiDoingCode",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Ohjelmistokehittäjä",
      title: "Tu Phung",
      discription:
        "Hallitsen C# ja JavaScript kielet sekä .NET Core, React ja Node.js framework ohjelmoinnin.\nPystyn oppimaan uudet ohjelmointikielet lyhyessä ajassa ja näin vastaamaan yrityksen tarpeisiin.\nLukiossa olin huippuoppilas. Nykyään opiskelen Tampereen ammattikorkeakoulussa ja  keskiarvoni on 5.0/5.0.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Esittelyni",
      aHead: "Intohimoinen ohjelmoija | \nAkateeminen opiskelija",
      aHeadTwo: "Ohjelmistokehittäjän harjoittelija",
      adiscription:
        "Minulla on erinomaiset ongelmanratkaisutaidot ja vahva tausta data-analyyseistä.\nOlen tottunut kansainvälisiin työympäristöihin, koska olen työskennellyt kansainvälisessä toimistossa, mikä on edellyttänyt intensiivistä ongelmanratkaisukykyä ja vastuuta.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Back-End",
      text: "Javascript ja C#",
      complateProject: "Backendin kehityksen hallitseminen .NET Core ja Node.js framework -ohjelmoinnin avulla",
      icon: "icon-compass",
    },
    {
      id: 2,
      workName: "Front-End",
      text: "React JS",
      complateProject: "Vahva Javascript-taito, mukaan lukien DOM-manipulaatio ja JavaScript-objektimalli. Reactin, Reduxin ja niiden ydinperiaatteiden perusteellinen ymmärtäminen",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "Cloud",
      text: "AWS",
      complateProject: "AWS S3 bucket, EC2, cloudfront",
      icon: "icon-cloud",
    },
    {
      id: 4,
      workName: "CI/CD",
      text: "CircleCI",
      complateProject: "CI/CD pipelinen määrittäminen, automaattinen käyttöönotto ja yksikkötestit käyttöliittymä- ja taustasovelluksille",
      icon: "icon-recycle",
    },
    {
      id: 5,
      workName: "Tietokanta",
      text: "SQL ja MongoDB",
      complateProject: "Skriptien, menettelyjen ja triggerien kehittäminen sovellusten kehittämiseen PostgreSQL:n ja MongoDB:n avulla",
      icon: "icon-attachment",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default jhonDataFinnish;
